import { BUILD_ID } from "../../analytics";
import {
  mockBookingAvailability,
  mockSchedulingLinkDto,
  mockSchedulingLinkDtos,
} from "../../mock/schedulingLinks.mock.data";
import {
  BookingAvailabilityDto,
  BookingAvailabilityTimeDto,
  SchedulingLinkDto,
  SchedulingLinkIconTypeDto,
  SchedulingLinkMeetingRequestDto,
} from "../../mock/schedulingLinks.mock.types";
import { Override } from "../../types";
import { dateToStr } from "../../utils/dates";
import { DayOfWeek } from "../Calendars";
import { ConferenceType as ConferenceTypeDto, DayOfWeek as DayOfWeekDto } from "../client";
import { EventType } from "../EventMetaTypes";
import { dtoToEvent, Event, eventToDto } from "../Events";
import { ConferenceType } from "../OneOnOnes";
import { TransformDomain } from "../types";
import { ThinPerson } from "../Users";
import { ReclaimApi } from "./scheduling-links-client";

const API_BASE_URI = process.env.NEXT_PUBLIC_API_BASE_URI;

export type SchedulingLinkIconType = `${SchedulingLinkIconTypeDto}`;

/**
 * SchedulingLink
 */

export type SchedulingLink = Readonly<
  Override<
    SchedulingLinkDto,
    {
      id: string;
      startDate: Date | undefined;
      endDate: Date | undefined;
      organizer: ThinPerson;
      timePolicy?: EventType;
      daysActive?: DayOfWeek[];
      conferenceType?: ConferenceType;
      iconType: SchedulingLinkIconType;
    }
  >
>;

export const dtoToSchedulingLink = (dto: SchedulingLinkDto): SchedulingLink => ({
  ...dto,
  startDate: dto.startDate ? new Date(dto.startDate) : undefined,
  endDate: dto.endDate ? new Date(dto.endDate) : undefined,
  timePolicy: EventType.get(dto.timePolicy),
  daysActive: dto.daysActive as unknown as DayOfWeek[],
  conferenceType: (dto.conferenceType as unknown as ConferenceType) || ConferenceType.None,
});

export const schedulingLinkToDto = (data: SchedulingLink): SchedulingLinkDto => ({
  ...data,
  startDate: dateToStr(data.startDate),
  endDate: dateToStr(data.endDate),
  timePolicy: data.timePolicy?.key,
  daysActive: data.daysActive as unknown as DayOfWeekDto[],
  conferenceType: (data.conferenceType === ConferenceType.None
    ? undefined
    : data.conferenceType) as unknown as ConferenceTypeDto,
  iconType: SchedulingLinkIconTypeDto[data.iconType],
});

/**
 * BookingAvailabilityTime
 */

export type BookingAvailabilityTime = Readonly<
  Override<
    BookingAvailabilityTimeDto,
    {
      startTime: Date;
      endTime: Date;
    }
  >
>;

export const dtoToBookingAvailabilityTime = (dto: BookingAvailabilityTimeDto): BookingAvailabilityTime => ({
  ...dto,
  startTime: new Date(dto.startTime),
  endTime: new Date(dto.endTime),
});

export const bookingAvailabilityTimeToDto = (data: BookingAvailabilityTime): BookingAvailabilityTimeDto => ({
  ...data,
  startTime: data.startTime.toISOString(),
  endTime: data.endTime.toISOString(),
});

/**
 * BookingAvailability
 */

export type BookingAvailability = Readonly<
  Override<
    BookingAvailabilityDto,
    {
      inviteeEvents?: Event[];
      times: BookingAvailabilityTime[];
    }
  >
>;

export const dtoToBookingAvailability = (dto: BookingAvailabilityDto): BookingAvailability => ({
  ...dto,
  inviteeEvents: dto.inviteeEvents?.map(dtoToEvent),
  times: dto.times.map(dtoToBookingAvailabilityTime),
});

export const bookingAvailabilityToDto = (data: BookingAvailability): BookingAvailabilityDto => ({
  ...data,
  inviteeEvents: data.inviteeEvents?.map(eventToDto),
  times: data.times.map(bookingAvailabilityTimeToDto),
});

/**
 * SchedulingLinkMeetingRequest
 */

export type SchedulingLinkMeetingRequest = Readonly<
  Override<
    SchedulingLinkMeetingRequestDto,
    {
      start: Date;
      end: Date;
    }
  >
>;

export const dtoToSchedulingLinkMeetingRequest = (
  dto: SchedulingLinkMeetingRequestDto
): SchedulingLinkMeetingRequest => ({
  ...dto,
  start: new Date(dto.start),
  end: new Date(dto.end),
});

export const schedulingLinkMeetingRequestToDto = (
  data: SchedulingLinkMeetingRequest
): SchedulingLinkMeetingRequestDto => ({
  ...data,
  start: data.start.toISOString(),
  end: data.end.toISOString(),
});

export class SchedulingLinksDomain extends TransformDomain<SchedulingLink, SchedulingLinkDto> {
  /**
   * This domain currently has its own separate client generation. Use
   * the domainApi instead of api for executing module requests.
   */
  domainApi: ReclaimApi;

  constructor(...args) {
    super(...args);

    this.domainApi = new ReclaimApi({ baseUrl: API_BASE_URI, BUILD_ID });
  }

  resource = "SchedulingLink";
  cacheKey = "scheduling_links";
  pk = "id";

  public serialize = schedulingLinkToDto;
  public deserialize = dtoToSchedulingLink;

  list = this.deserializeResponse(async () => {
    return new Promise<SchedulingLinkDto[]>((resolve) => {
      setTimeout(() => resolve(mockSchedulingLinkDtos()), 2000);
    });
  });

  get = this.deserializeResponse((id: string): Promise<SchedulingLinkDto> => {
    const mock = mockSchedulingLinkDto();
    mock.id = id;
    return new Promise<SchedulingLinkDto>((resolve) => {
      setTimeout(() => resolve(mockSchedulingLinkDto()), 2000);
    });
  });

  getBooking = (start: Date, end: Date) =>
    new Promise<BookingAvailability>((resolve) => {
      setTimeout(() => resolve(dtoToBookingAvailability(mockBookingAvailability())), 2000);
    });

  requestBooking = (request: SchedulingLinkMeetingRequest) =>
    new Promise<void>((resolve) => {
      setTimeout(() => resolve(), 2000);
    });
}
