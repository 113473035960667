/* eslint-disable no-restricted-imports */
import { setHours, setMinutes } from "date-fns";
import { EventType } from "../reclaim-api/client";
import { hashBoolean } from "../utils/dev";
import {
  BookingAvailabilityDto,
  BookingAvailabilityTimeDto,
  SchedulingLinkDto,
  SchedulingLinkIconTypeDto,
} from "./schedulingLinks.mock.types";

export const mockBookingAvailabilityTime = (hour: number, minute: number): BookingAvailabilityTimeDto => {
  const now = new Date(`2022-10-13T00:00:00.000Z` );
  const start = setMinutes(setHours(now, hour), minute);
  return {
    startTime: start.toISOString(),
    endTime: setMinutes(start, 30).toISOString(),
    suggested: hashBoolean(`${hour}:${minute}`),
  };
};

export const mockBookingAvailability = (hasInviteeEvents?: boolean): BookingAvailabilityDto => ({
  times: [
    mockBookingAvailabilityTime(9, 30),
    mockBookingAvailabilityTime(10, 0),
    mockBookingAvailabilityTime(10, 30),
    mockBookingAvailabilityTime(12, 0),
    mockBookingAvailabilityTime(12, 30),
    mockBookingAvailabilityTime(1, 0),
    mockBookingAvailabilityTime(1, 30),
  ],
  // TODO: mock out events
  inviteeEvents: hasInviteeEvents ? [] : undefined,
});

export const mockSchedulingLinkDto = (): SchedulingLinkDto => ({
  id: "8d8447d7-c93d-48d1-951d-fe6365944244",
  title: "30 minute meeting",
  description:
    "A long description of my meeting link. Sit dicta perspiciatis solutall am ut saepe et id facere omnis. Qui aspernatur necess itatibus quidem et in vel.",
  enabled: true,
  organizer: {
    avatarUrl: "https://lh3.googleusercontent.com/a-/AFdZucojejfHkMEA5O6Sp_81QYNFKT1C1OV93XJzyhsH=s96-c",
    email: "reclaim.office2@gmail.com",
    name: "Michael Scott",
    userId: "ee8be6dc-7fc0-435c-9bb1-8d12c7c0b581",
  },
  timePolicy: EventType.MEETING,
  daysActive: undefined,
  windowStart: undefined,
  windowEnd: undefined,
  durationMin: 30,
  durationMax: 30,
  daysIntoFuture: 14,
  startDate: undefined,
  endDate: undefined,
  priority: "DEFAULT",
  conferenceType: undefined,
  iconType: SchedulingLinkIconTypeDto.BOLT,
});

export const mockSchedulingLinkDtos = (): SchedulingLinkDto[] => {
  const archetype = mockSchedulingLinkDto();
  const description = "A short description of my meeting link.";

  return [
    {
      ...archetype,
      title: "Short Meeting",
      description,
      durationMin: 15,
      durationMax: 30,
      iconType: SchedulingLinkIconTypeDto.COFFEE,
    },
    archetype,
    {
      ...archetype,
      title: "Priority Meeting",
      description,
      durationMin: 60,
      durationMax: 60,
      iconType: SchedulingLinkIconTypeDto.LIGHT,
    },
  ];
};
