/* eslint-disable no-restricted-imports */
import { ConferenceType, DayOfWeek, Event, EventType, ThinPerson } from "../reclaim-api/client";

export enum SchedulingLinkIconTypeDto {
  LIGHT = "LIGHT",
  COFFEE = "COFFEE",
  BOLT = "BOLT",
  COMMENT = "COMMENT",
  STAR = "STAR",
  AIRPLANE = "AIRPLANE",
  TWINKLE = "TWINKLE",
  COMPASS = "COMPASS",
  SUN = "SUN",
  SMILE = "SMILE",
}

export type SchedulingLinkDto = {
  id: string;
  title: string;
  description: string;
  enabled: boolean;
  organizer: ThinPerson;
  timePolicy?: EventType;
  daysActive?: DayOfWeek[];
  windowStart?: string;
  windowEnd?: string;
  durationMin: number;
  durationMax: number;
  daysIntoFuture?: number;
  startDate?: string;
  endDate?: string;
  priority: "DEFAULT" | "HIGH";
  conferenceType?: ConferenceType;
  iconType: SchedulingLinkIconTypeDto;
};

export type BookingAvailabilityDto = {
  inviteeEvents?: Event[];
  times: BookingAvailabilityTimeDto[];
};

export type BookingAvailabilityTimeDto = {
  startTime: string;
  endTime: string;
  suggested: boolean;
};

export type SchedulingLinkMeetingRequestDto = {
  start: string;
  end: string;
  inviteeName: string;
  inviteeEmail: string;
  ccEmails?: string[];
  message?: string;
};
